
<template>
 <vx-card>
  <div class="vx-row">
    <div class="vx-col md:w-full mt-4">
      <span style="font-weight: bold;">NB:</span> <span style="font-style: italic;"> Les champs suivis du signe (*) sont obligatoires pour l'enregistrement du centre hospitalier.</span>
    </div>
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Raison Sociale *" v-model="RaisonSoc" class="w-full" />
    </div>
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Adresse *"  v-model="AdrCli" class="w-full" />
    </div>
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input type="email" label="Email" v-model="MailCli" class="w-full" />
    </div>
  </div>
  <div class="vx-row">
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Téléphone 1" v-model="TelCli1" class="w-full" />
    </div>
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Téléphone 2" v-model="TelCli2" class="w-full" />
    </div>
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Téléphone 3" v-model="TelCli3" class="w-full" />
    </div>
  </div>
  <div class="vx-row">
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Mobile 1" v-model="MobCli1" class="w-full" />
    </div>
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Mobile 2" v-model="MobCli2" class="w-full" />
    </div>
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Fax" v-model="FaxCli" class="w-full" />
    </div>
  </div>
  <div class="vx-row mt-base">
    <div class="vx-col text-right w-full ml-auto">
      <vs-button color="warning" type="border" class="mr-3 mb-2" @click="gotToCentreHospitalier">Annuler</vs-button>
      <vs-button class="mb-2" :disabled="!validateForm" @click="centreUpdated" >Enregistrer</vs-button>
    </div>
  </div>
 </vx-card>

</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      RaisonSoc: null,
      AdrCli: null,
      MailCli: null,
      TelCli1: null,
      TelCli2: null,
      TelCli3: null,
      MobCli1: null,
      MobCli2: null,
      FaxCli:null
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.RaisonSoc !== null && this.AdrCli !== null
    }
  },
  methods: {
    gotToCentreHospitalier () {
      this.$router.push('/apps/centre_hopitalier/centre_hopitalier-list').catch(() => {})
    },
    centreUpdated () {
      const payload = {
        RaisonSoc: this.RaisonSoc,
        AdrCli: this.AdrCli,
        MailCli: this.MailCli,
        TelCli1: this.TelCli1,
        TelCli2: this.TelCli2,
        TelCli3: this.TelCli3,
        MobCli1: this.MobCli1,
        MobCli2: this.MobCli2,
        FaxCli: this.FaxCli,
        id: this.$route.params.centreId
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('centre_hospitalier/updateCentreHospitalier', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$router.push('/apps/centre_hopitalier/centre_hopitalier-list').catch(() => {})
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getCentreHospitalierById () {
      this.$store.dispatch('centre_hospitalier/getCentreHospitalierById', this.$route.params.centreId)
        .then(res => {
          this.RaisonSoc = res.data.RaisonSoc ? res.data.RaisonSoc : null
          this.AdrCli = res.data.adresse.AdrCli ? res.data.adresse.AdrCli : null
          this.MailCli = res.data.adresse.MailCli ? res.data.adresse.MailCli : null
          this.TelCli1 = res.data.adresse.TelCli1 ? res.data.adresse.TelCli1 : null
          this.TelCli2 = res.data.adresse.TelCli2 ? res.data.adresse.TelCli2 : null
          this.TelCli3 = res.data.adresse.TelCli3 ? res.data.adresse.TelCli3 : null
          this.MobCli1 = res.data.adresse.MobCli1 ? res.data.adresse.MobCli1 : null
          this.MobCli2 = res.data.adresse.MobCli2 ? res.data.adresse.MobCli2 : null
          this.FaxCli = res.data.adresse.FaxCli ? res.data.adresse.FaxCli : null
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  created () {
    this.getCentreHospitalierById()
  }
}
</script>
